<template>
  <modal
    transition="nice-modal-fade"
    width="680"
    :adaptive="true"
    :reset="true"
    height="auto"
    :name="modalName"
    class="modal--midtrans-overlay"
    @before-open="beforeOpen"
  >
    <div class="instructions--wrapper">
      <div class="instructions">
        {{ $t('booking.midtransInstruction') }}
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'midtrans-overlay',
  data: () => ({
    modalName: 'modal-midtrans-overlay',
  }),
  methods: {
    close() {
      this.$modal.hide(this.modalName);
    },
    // eslint-disable-next-line no-unused-vars
    beforeOpen(event) {
      let self = this;
      setTimeout(() => {
        self.close();
      }, 5000);
    },
  },
};
</script>

<style scoped></style>
